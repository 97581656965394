export const IMMEDIO_BOX_LP_URL = "https://www.immedio.io/immedio-box";

export const getOrigin = (): string =>
	typeof window !== "undefined" ? window.location.origin : "";

/**
 * URLに含まれる+が空白に変換されるため、空白を+に変換する
 */
export function convertSpaceToPlus(value: unknown): string | undefined {
	if (typeof value !== "string") {
		return undefined;
	}
	return value.replace(/ /g, "+");
}
